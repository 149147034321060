<template>
  <head>
  <link rel="canonical" href="https://lhairderien-perruque.fr/">
  </head>
  <div id="app" v-scroll="handleScroll" >
    <div id="myModal" class="modalNoel">

      <div class="modalContent">
        <span class="modalNoel-close" style="z-index: 9999;">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-scissors-off" width="34" height="34" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4.432 4.442a3 3 0 1 0 4.114 4.146" />
            <path d="M6 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            <path d="M8.6 15.4l3.4 -3.4m2 -2l5 -5" />
            <path d="M3 3l18 18" />
          </svg>
        </span>
        <div class="containerTitleNoel">
          <!-- <h1 class="title_noel" style="font-family: Neuton;">Joyeuses<br>fêtes</h1> -->
          <div class="container_paragraph_noel" style="text-align: center;">
            <!-- <p class="paragraphNoel_1" style="font-family: Neuton;">Pour une prothèse capillaire achetée</p>
            <p class="paragraphNoel_1" style="font-family: Neuton;">=</p>
            <p class="paragraphNoel_2" style="font-family: Neuton;">Un massage réflexologie plantaire</p>
            <p class="paragraphNoel_3" style="font-family: Neuton;">de 30 minutes offert</p> -->
          </div>
          <!-- <div style="text-align: center; position:absolute; bottom:18px">
            <p class="paragraphNoel_1" style="font-family: Neuton;">Du 15 décembre au 16 janvier</p>
            <p class="paragraphNoel_1" style="font-family: Neuton;">Offre valable à un bon par personne</p>
          </div> -->
        </div>
        <img class="imgGifNoel" src="../assets/octobre_rose.jpg" alt="GIF animé">

      </div>
  </div>

    <div class="accueil" v-scroll="handleScrollx">
      <video-bg type="mp4" :sources="[require('../assets/videoHeader.webm')]" >
        <div class="firstContainerWidget">
          <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="" >
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>
      <!-- If you want to add content here, a slot is waiting! -->
      </video-bg>
      <!-- <h1 class="title_tab">L'HAIR DE RIEN</h1> -->
      <div class="container_presta">
        <p v-for="prestation in prestations" :key="prestation" class="paragraph_home" >
          {{ prestation.title }}
        </p>
      </div>
      <!-- <p>The value of : {{ value }}!</p>
      <p>The value of : {{ value2 }}!</p> -->

      <p class="paragraph_home_intro">
        Situées en périphérie de Marseille, Floriane et Laetitia vous accueilleront dans leur univers en écoutant vos attentes, vos craintes, vos peurs et vos interrogations. Toutes deux diplomées en coiffure et prothèse capillaire, nous adapterons nos techniques en fonction de vos besoins.
      </p>
      <div class="container_images_home">
        <div @mouseover="setCurrent(item.id)" class="container-image"  v-for="item in tab_info_img" :key="item">
          <img :src="item.url" :alt="item.alt">
          <div class="background_link_list" v-if="value > 1000"  >
              <h2  style="display:block" class="img_title">{{ item.title }}</h2>
              <LinkList  :tab_info_img="item"/>
          </div>
        </div>
      <div>
    </div>
    </div>
    <div class="testb">
    <div class="container_text_images">
      <h5 class= "title_home1">Du côté des femmes</h5>
      <div class="container_p_img">
      <div class="container_text">
        <p class="paragraph_femme_home">1 femme sur 3 n'est pas satisfaite de la qualité de ses cheveux.
          Que ce soit le volume, la densité, la longueur ou la texture. Trop fragiles, trop secs ou bien incoiffables.<br>
          Les causes peuvent être diverses: chimiothérapie, radiothérapie, problème de thyroïde, pelade, carences, post partum, chute saisonnière, cheveux maltraités ou simplement un besoin de changement capillaire.<br>
          Chez l'HAIR DE RIEN nous seront ravis de vous aider et faire un diagnostic gratuit afin trouver la solution la plus adaptée
        </p>
      </div>
      <div class="container2_images_home">
        <img :src="images_1[0].url" :alt="images_1[0].alt">
      </div>
      </div>
      </div>

      <div class="container_text_images">
        <h5 class= "title_home2">Du côté des hommes</h5>
        <div class="container_p_img1">
        <div class="container_text">
          <p class="paragraph_femme_home">L'héridité, le stress, les maladies, les accidents ou bien les carences peuvent être la cause d'une perte de densité capillaire chez l'homme.
            A partir de 50 ans, 50% des hommes ont une alopécie androgénétique, autrement appelé calvitie. Avec la vie que nous menons actuellement, ces chiffres ont tendence à augmenter et la perte de cheveux arrive de plus en plus tôt. Les implants de cheveux ne sont pas toujours la solution la plus adpatée alors chez l'HAIR DE RIEN nous avons trouvé des alternatives sans toucher à votre capital cheveux, sans chirurgie, sans cicatrices: Vous pouvez retrouver vos cheveux en quelques heures
          </p>
        </div>
        <div class="container2_images_home">
        <img :src="images_1[1].url" :alt="images_1[1].alt">
        </div>
        </div>
      </div>

      <!-- <div class="container_text_images">
        <h1 class= "title_home1">Nous sommes là pour vous accompagner</h1>
        <div class="container_p_img">
        <div class="container_text">
          <p class="paragraph_femme_home">Nous vivons dans une époque où l'on place notre image au premier plan. Etre beau/belle, paraitre jeune le plus longtemps possible, rester à la mode. Notre image est sans cesse mise à rude épreuve et il est souvent difficile de se montrer tel que l'on est. Apres une perte de cheveux importante notre estime de soi peut diminuer considérablement. C'est pour cela que nous vous accompagnons.</p>
        </div>
        <div class="container2_images_home">
        <img :src="images_1[2].url" alt="">
      </div>
      </div>
      </div> -->

      <div class="container_text_images2">
        <h3 class= "title_home3">Nous sommes là pour vous accompagner</h3>
        <div class="container_p_img2">
        <div class="container_text1">
        </div>
      <div class="container2_images_home1">
        <img :src="images_1[2].url" :alt="images_1[2].alt">
      </div>
      <p class="paragraph_femme_home1">Nous vivons dans une époque où l'on place notre image au premier plan. Etre beau/belle, paraitre jeune le plus longtemps possible, rester à la mode. Notre image est sans cesse mise à rude épreuve et il est souvent difficile de se montrer tel que l'on est. Apres une perte de cheveux importante notre estime de soi peut diminuer considérablement. C'est pour cela que nous vous accompagnons.
          </p>
        </div>
      </div>

    <div class="container_text_images1">
        <h3 class= "title_home3">Combattre la perte de cheveux avec des solutions adaptées</h3>
        <div class="container_p_img1">
        <div class="container_text1">
          <p class="paragraph_femme_home">L'héridité, le stress, les maladies, les accidents ou bien les carences peuvent être la cause d'une perte de densité capillaire chez l'homme.
            A partir de 50 ans, 50% des hommes ont une alopécie androgénétique, autrement appelé calvitie. Avec la vie que nous menons actuellement, ces chiffres ont tendence à augmenter et la perte de cheveux arrive de plus en plus tôt. Les implants de cheveux ne sont pas toujours la solution la plus adpatée alors chez l'HAIR DE RIEN nous avons trouvé des alternatives sans toucher à votre capital cheveux, sans chirurgie, sans cicatrices: Vous pouvez retrouver vos cheveux en quelques heures
          </p>
        </div>
        <!-- <div class="container2_images_home"> -->
        <!-- <img :src="images_1[1].url" alt=""> -->
        <!-- </div> -->
        </div>
      </div>
      <!-- <div class="powr-instagram-feed" id="33a41c56_1662142182"></div> -->
      </div>

    </div>

  </div>
  <!-- <div style="background:black; height: 100px; width: 100%; display: flex; justify-content: center;">
  <button style="background:white; color:black;" @click="numberOfString">click me !</button>
</div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import VideoBg from 'vue-videobg'
import LinkList from './LinkList.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Institut specialisé dans les solutions capillaires',
      description: 'Solutions à la perte de cheveux: Prothèses, membranes capillaires modernes en cheveux naturels, perruques médicales, perruques cheveux naturels, volumateurs etc',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'google-site-verification',
          content: 'uOM3cNvI3fHXQu0RSaxwNLiDLtUjXTtvUXdTuvVI0Ms'
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      array: 'Solutions à la perte de cheveux: Prothèses, membranes capillaires modernes en cheveux naturels, perruques médicales, perruques cheveux naturels, volumateurs etc',
      value: 0,
      value2: 0,
      // access_token: 'your access token here',
      // url: 'https://api.instagram.com/v1/users/self/media/recent/',
      // username: '',
      // grams: [],
      // next_url: '',
      // error: false,
      current: false,
      isActive: false,
      images: [
        { url: require('../assets/femme1_home.webp'), alt: 'prise en charge secu perruque' },
        { url: require('../assets/homme_home_1.webp'), alt: 'Now with dog - Rosé' },
        { url: require('../assets/salon_home_edited_1.webp'), alt: 'Jeg er i Danmark' }
      ],
      images_1: [
        { url: require('../assets/femme-1.webp'), alt: 'coupe-femme' },
        { url: require('../assets/homme-1.webp'), alt: 'coupe-homme' },
        { url: require('../assets/hands.webp'), alt: 'prise en charge secu perruque' }
      ],
      prestations: [
        { title: 'PERRUQUE', to: '/perruques' },
        { title: 'COMPLEMENT CAPILLAIRE', to: '/complements-capillaires' },
        { title: 'TOUPET', to: '/volumateurs' }
      ],
      tab_info_img: [
        {
          id: 1,
          title: 'femmes',
          link: 'link femme',
          url: require('../assets/femme1_home.jpg'),
          alt: 'perruque femme chimio',
          sublist1: [
            { name: 'Complements Capillaires', to: '/complements-capillaires-femme' },
            { name: 'Perruques Médicales', to: '/perruques-medicales-femmes' },
            { name: 'Perruques Cheveux Naturels', to: '/perruques-cheveux-naturels' },
            { name: 'Perruques Fibre Prime', to: '/perruques-fibre-prime' },
            { name: 'Perruques Relooking', to: '/perruques-relooking' },
            { name: 'Perruques Type Lace', to: '/perruques-type-lace' },
            { name: 'Bonnets Et Turbans', to: '/bonnets-turbans' },
            { name: 'Franges Et Couronnes', to: '/franges-couronnes' },
            { name: 'Volumateurs', to: '/volumateurs' },
            { name: 'Postiches', to: '/postiches' }
          ]
        },
        {
          id: 2,
          title: 'hommes',
          link: 'link hommes',
          url: require('../assets/homme_home_1.jpg'),
          alt: 'complement capillaire homme',
          sublist1: [
            { name: 'Complements Capillaires', to: '/complements-capillaires-homme' },
            { name: 'Conseils', to: '/conseils-complements-capillaires-homme' },
            { name: 'Perruques', to: '/perruques-homme' }
          ]
        },
        {
          id: 3,
          title: 'salon',
          link: 'link salon',
          url: require('../assets/salon_home_edited_1.jpg'),
          alt: 'institut capillaire aubagne',
          sublist1: [
            { name: 'Institut', to: '/salon' },
            { name: 'Qui Sommes Nous', to: '/qui-sommes-nous' }
          ]
        }
      ]
    }
  },
  // computed: {
  //   instapage () {
  //     return 'https://www.instagram.com/' + this.username
  //   }
  // },
  components: {
    VideoBg,
    LinkList
    // InstagramEmbed
    // VueInstagram
  },
  // mounted () {
  //   // Récupérer les éléments nécessaires
  //   const modalNoel = document.getElementById('myModal')
  //   // const modalContent = document.querySelector('.modalContent')
  //   const closeButton = document.querySelector('.modalNoel-close')

  //   // Fonction pour fermer la modal
  //   function closeModal () {
  //     modalNoel.style.display = 'none'
  //   }

  //   // Ajouter un écouteur d'événement pour le bouton de fermeture
  //   closeButton.addEventListener('click', closeModal)

  //   // Ajouter un écouteur d'événement pour la modal
  //   modalNoel.addEventListener('click', function (event) {
  //     if (event.target === modalNoel) {
  //       closeModal()
  //     }
  //   })

  //   const modal = document.getElementById('myModal')
  //   modal.style.display = 'flex' // Affiche la fenêtre modale
  // },

  // beforeRouteLeave (to, from, next) {
  //   const modal = document.getElementById('myModal')
  //   modal.style.display = 'none' // Masque la fenêtre modale
  //   next()
  // },

  methods: {

    // mouseOver () {
    //   this.isActive = !this.isActive
    // },
    setCurrent (id) {
      this.current = id
      // const scrollTop = window.scrollY
      // console.log(scrollTop)
    },
    handleScroll () {
      this.value = window.scrollY
      if (this.value > 566) {
        this.isActive = !this.isActive
        // console.log(this.value)
      } else {
        // console.log('coucou')
      }
    },
    numberOfString () {
      const numberofcharac = this.array.length
      console.log(numberofcharac)
    },
    handleScrollx () {
      this.value2 = window.scrollX
      // console.log(this.value2)
      // console.log('coucou2')
    }
    // onScroll () {
    //   const scrollTop = window.scrollY
    //   console.log(scrollTop)
    //   if (scrollTop < 2000) {
    //     console.log('hello')
    //   }
    // }
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Accueil'
  // components: {
  //   HelloWorld
  // }
})
</script>
<style lang="scss" scoped>

.powr-instagram-feed {width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    float: right;
    .blog {
      display: none;
    }
  }

.selected {
  background: red;
}
.accueil {
  width: 80%;
  right: 0;
  text-align: center;
  float: right;
}
.container_images_home {
  width:100%;
  display:flex;
  .container-image {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-grid;
  }
  img {
    width: 100%;
  }
}
.container_text_images{
  width:100%;
  img{
    width: 100%;
  }
}
.container_text_images1 {
  width:90%;
  margin-bottom: 30px;
  img{
    width: 100%;
  }
}
.container_text_images2 {
  width:90%;
  img{
    width: 100%;
  }
}
.container2_text_images {
  width:100%;
  img{
    max-width: 50%;
  }
}
.container_text_images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  width: 90%;
}
.title_home1 {
  position: absolute;
  left: 25%;
}
.title_home2 {
  position: absolute;
  right: 5%;
}
.title_home3 {
  right: 20%;
}
.container2_text_images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom:50px;

}
.container_text {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
}
.container_text1 {
  display: flex;
  flex-direction: column;
}
.container_images_home {
  margin: 25px 0px 50px 0px;
  width: 100%;

}
.container_presta {
  margin-bottom: 15px;
  gap: 30px;
  margin-top: 30px;
  display:flex;
  align-items:center;
  justify-content:center;
}
@media(max-width:550px) {
  .container_presta {
    gap:10px;
    display:none;
  }
  .paragraph_home {
    font-size: 12px !important;
  }
}
.paragraph_femme_home {
  margin: 40px 5px 60px 5px;
}
.paragraph_femme_home1 {
  margin: 10px 5px 60px 5px;
}
.container_p_img1 {
  display: flex;
  flex-direction: row-reverse;
  // width: 80%;
  gap: 60px;
}
.container_p_img2 {
  display: flex;
  flex-direction: column;
  // width: 80%;
  gap: 10px;
}
.container_p_img {
  display: flex;
  // width: 80%;
  gap:60px;
}
.background_link_list {
  background: rgba(0, 0, 0, 0.555);
  width:100%;
  height: 100%;
  position: absolute;
  top: 0;
  right:0;
  z-index: 9998;
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.img_title {
  margin-bottom: 40px;
  display: none;
}
.container2_images_home {
  width:50%;
}
.container2_images_home1 {
  width:100%;
}
.testb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1410px) {
  .paragraph_femme_home {
  }
}
@media (max-width: 990px) {
  .accueil {
    width:100%;
    .paragraph_femme_home {
  }
  }
  .paragraph_femme_home {
     margin: 0px 5px 50px 5px;
  }
  .container_text_images{
    margin-bottom: 40px;
  }
  .container_p_img, .container_p_img1 {
    flex-direction:column-reverse;
  }
  .container_p_img img, .container_p_img1 img {
    margin-top:50px;
    max-width: 100%;
  }
  .container_text {
    width: 100%;
  }
  .container2_images_home {
    width: 100%;
  }
  .container_text img {
    margin-top: 0;
  }
  .title_home1 {
    left: 35%;
    margin-bottom:10px;
  }
  .title_home2 {
    margin-bottom:10px;
    right: 0%;
    left: 5%;
  }
//   .container_p_img1 {
//   flex-direction: column-reverse;
// }
.title_home3 {
    left: 0%;
    margin-bottom:10px;
  }
  .container_p_img1, .container_p_img {
    gap: 20px;
  }
}
@media (max-width: 550px) {
.container_images_home {
  width:100%;
  flex-direction: column;
  img {
    max-width: 100%;
  }
}
.paragraph_femme_home {
     margin: 0px 5px 50px 5px;
  }
.title_home1, .title_home3 {
    left: 5%;
    margin-bottom:10px;
  }
  .title_home2 {
    right: auto;
    left: 5%;
  }
  .container_p_img img, .container_p_img1 img {
    margin-top:30px;
    max-width: 100%;
  }
  .container_p_img1, .container_p_img {
    gap: 20px;
  }
}
</style>
